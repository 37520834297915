/*
	~ Order Shipping Container Component
*/

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import redHighImage from '@images/used_red_can.png';
import blueHighImage from '@images/40ft_can_blue_transparent.png';

// import { useState } from 'react';

import { CSSObject } from '@emotion/react';
import { withStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
	Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme, Typography, useMediaQuery 
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import {
	ContainerOptions, ContainerOptionTypes, Containers, ContainerTypes 
} from '@interfaces/containers';
import {
	SyntheticEvent, useEffect, useState 
} from 'react';


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		flexDirection: 'column',
	} as CSSObject,
	checkmark: {
		fontSize: 25,
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	checkmarkContainer: {
		top: -5,
		right: -5,
		padding: 3,
		display: 'flex',
		position: 'absolute',
		borderRadius: '50%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.main,
	} as CSSObject,
	finalReviewContainerImage: {
		width: '90%',
		height: 'auto',
		maxHeight: 275,
		marginBottom: 20,

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '50%',
		},
	} as CSSObject,
	containerQuantity: {
		marginLeft: 10,
		marginRight: 10,
	} as CSSObject,
	containerName: {
		width: '100%',
		marginTop: 15,
	} as CSSObject,
	desktopQuantityChanger: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	conditionContainer: {
		width: '50%',

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '95%',
		},
	} as CSSObject,
	conditionFormContainer: {
		width: 143,

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
		},
	} as CSSObject,
	countWrapperOpened: {
		height: 143,
	} as CSSObject,
	mobileQuantityChangerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iOrderShippingContainerProps extends getClassesType<stylesType> {
	isSelected?: boolean,
	containerType: ContainerTypes,
	conditionType?: ContainerOptionTypes,
	onQuantityChange?: (value: number) => void,
	onConditionChange?: (value: ContainerOptionTypes & '') => void,
}

const OrderShippingContainer = (props: iOrderShippingContainerProps) => {
	const {
		containerType,
		conditionType,
		onQuantityChange,
		onConditionChange,
		isSelected = false,
	} = props;

	console.log(`conditionType: INSIDE `, conditionType);

	const theme = useTheme();
	const classes = withStyles.getClasses<stylesType>(props);
	const [containerQuantity, setContainerQuantity] = useState(1);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	useEffect(() => {
		if (isSelected) {
			if (onQuantityChange) {
				onQuantityChange(containerQuantity);
			}
		}
	}, [isSelected]);

	useEffect(() => {
		if (onQuantityChange) {
			onQuantityChange(containerQuantity);
		}
	}, [containerQuantity]);

	const getContainerImageByType = (key: ContainerTypes | null) => {
		let img = null;
		
		if (key && key !== null) {
			switch (key) {
				case Containers.size20Standard:
					img = (
						<img 
							src={redHighImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
					
				case Containers.size40High:
					img = (
						<img 
							src={blueHighImage}
							alt={getContainerNameByType(key)}
							className={classes.finalReviewContainerImage} />
					);
					break;
			
				default:
					break;
			}
		}

		return img;
	};

	const getContainerNameByType = (key: ContainerTypes | null) => {
		let name = 'none';
		
		if (key && key !== null) {
			switch (key) {
				case Containers.size20Standard:
					name = `Size 20' Standard`;
					break;
				case Containers.size40High:
					name = `Size 40' High Cube`;
					break;
			
				default:
					break;
			}
		}

		return name;
	};

	const handleConditionChange = (event: SelectChangeEvent) => {
		event.preventDefault();
		event.stopPropagation();

		if (onConditionChange) {
			const value = event?.target?.value;

			if (value) {
				onConditionChange(value as ContainerOptionTypes & '');
			}
		}
	};

	const renderConditionTypeMenu = (isAllowedToHide = true) => {
		return (
			<div 
				className={classes.conditionContainer}
				style={{
					opacity: isAllowedToHide && !isSelected ? 0 : 1,
				}}>
				<FormControl 
					variant="standard" 
					className={classes.conditionFormContainer}>
					<InputLabel id="condition-label">Condition</InputLabel>
					<Select
						label="Condition"
						id="condition-select"
						disabled={!isSelected}
						labelId="condition-label"
						value={conditionType || ''}
						onChange={handleConditionChange}>

						<MenuItem 
							value={''}
							onClick={(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}} />
						
						<MenuItem
							value={ContainerOptions.new}
							onClick={(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}}>
							New
						</MenuItem>

						<MenuItem 
							value={ContainerOptions.used}
							onClick={(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}}>
							Used
						</MenuItem>

						<MenuItem 
							value={ContainerOptions.likeNew}
							onClick={(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}}>
							Like  New
						</MenuItem>

						{
							containerType === Containers.size40High && (
								<MenuItem 
									value={ContainerOptions.sideDoors}
									onClick={
										(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}
									}>
									Side Doors
								</MenuItem>
							)
						}

						{
							containerType === Containers.size40High && (
								<MenuItem 
									value={ContainerOptions.doubleDoors}
									onClick={
										(event: SyntheticEvent) => {event.preventDefault(); event.stopPropagation()}
									}>
									Double Doors
								</MenuItem>
							)
						}

					</Select>
				</FormControl>
			</div>
		);
	};

	const renderQuantityStepper = (isAllowedToHide = true) => {
		return (
			<ButtonGroup 
				style={{
					marginRight: 25,
					opacity: isAllowedToHide && !isSelected ? 0 : 1,
				}}>
				<Button
					size='small'
					aria-label="reduce"
					disabled={isAllowedToHide && !isSelected}
					onClick={(event: SyntheticEvent) => {
						event.preventDefault();
						event.stopPropagation();

						setContainerQuantity(Math.max(containerQuantity - 1, 0));
					}}>
					<RemoveIcon fontSize="small" />
				</Button>
				
				<Button
					size='small'
					aria-label="reduce"
					disabled={isAllowedToHide && !isSelected}
					onClick={(event: SyntheticEvent) => {
						event.preventDefault();
						event.stopPropagation();

						setContainerQuantity(containerQuantity + 1);
					}}>
					<Typography variant='h6' className={classes.containerQuantity}>
						{ containerQuantity }
					</Typography>
				</Button>

				<Button
					size='small'
					aria-label="increase"
					disabled={isAllowedToHide && !isSelected}
					onClick={(event: SyntheticEvent) => {
						event.preventDefault();
						event.stopPropagation();

						setContainerQuantity(containerQuantity + 1);
					}}>
					<AddIcon fontSize="small" />
				</Button>
			</ButtonGroup>
		);
	};

	const renderQuantityChanger = () => {
		return (
			<div className={classes.desktopQuantityChanger}>
				{ renderQuantityStepper() }
				{ renderConditionTypeMenu() }
			</div>
		);
	};

	const renderMobileQuantityChanger = () => {
		return (
			<div className={classes.mobileQuantityChangerContainer}>
				{ renderConditionTypeMenu(false) }

				<Typography variant='body2' style={{
					marginTop: 35, 
					marginBottom: 15, 
				}}>
					Number of Containers
				</Typography>

				{ renderQuantityStepper(false) }
			</div>
		);
	};

	return (
		<div className={classes.container}>
			{
				isSelected && (
					<div className={classes.checkmarkContainer}>
						<CheckIcon className={classes.checkmark} />
					</div>
				)
			}

			<Typography variant='h6' className={classes.containerName}>
				{ getContainerNameByType(containerType) }
			</Typography>

			{ getContainerImageByType(containerType) }

			{
				isMobile ? renderMobileQuantityChanger() : renderQuantityChanger()
			}
		</div>
	);
};


export default withStyles(OrderShippingContainer, Styles);
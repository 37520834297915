
/**
 * Send an Email by Using Seacanman.ca App Engine
 * @param email 
 * @param subject 
 * @param body 
 * @returns 
 */
export const sendOrderToEmail = async (email: string, subject: string, body: string) => {
	try {
		const formdata = new FormData();
		formdata.append('to', email);
		formdata.append('body', body);
		formdata.append('subject', subject);

		const response = await fetch('https://seacanman.nn.r.appspot.com', {
			method: 'POST',
			body: formdata,
			redirect: 'follow',
		});

		return await response.json();

	} catch (error) {
		//
	}
};
/**
 * 
 * 	~ Container Interfaces & Types
 * 
 */

export enum Containers {
	size20Standard = 'size20Standard',
	size40High = 'size40High',
}

export enum ContainerOptions {
	new = 'new',
	used = 'used',
	likeNew = 'likeNew',
	sideDoors = 'sideDoors',
	doubleDoors = 'doubleDoors',
}

export type ContainerTypes = `${Containers}`;

export type ContainerOptionTypes = `${ContainerOptions}`;
/*
	~ App View
*/


import includeClasses from 'classnames';
import NavigatorView from '@views/Navigator';
import Theme from '@atoms/_materialUIThemeProvider';

import Styles, { styleType } from './styles';

import { withStyles } from 'tss-react/mui';
import { getClassesType } from '@interfaces/tssReact';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ScrollProvider } from '@atoms/_scroll';


interface iAppViewProps extends getClassesType<styleType> {}


/**
 * Application Base/Root Component
 * @param props 
 * @returns 
 */
const AppView = (props: iAppViewProps) => {
	const classes = withStyles.getClasses<styleType>(props);

	return (
		<Theme>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<ScrollProvider>
					<div className={
						includeClasses({
							[classes.container]: true,
						})
					}>
						<NavigatorView />
					</div>
				</ScrollProvider>
			</LocalizationProvider>
		</Theme>
	);
};

export default withStyles(AppView, Styles);
/**
 * 
 * 	~ Lure Row Styles
 * 
 */

import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';


const Styles = (theme: Theme) => ({
	container: {
		width: '100%',
		paddingTop: 0,
		display: 'flex',
		paddingBottom: 0,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingTop: 0,
			marginTop: '7.5vh',
		},
	} as CSSObject,
	lureInnerContainer: {
		width: '100%',
	} as CSSObject,
	lureContentRight: {
		paddingRight: 0,
		alignItems: 'flex-start',
	} as CSSObject,
	lureImage: {
		width: '80%',
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			height: '50vh',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
		},
	} as CSSObject,
	lureContent: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	} as CSSObject,
	rightButtonContainer: {
		paddingLeft: 0,
		justifyContent: 'flex-end',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: 0,
			justifyContent: 'center',
		},
	} as CSSObject,
	leftButtonContainer: {
		paddingRight: 0,
		justifyContent: 'flex-start',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			padding: 0,
			justifyContent: 'center',
		},
	} as CSSObject,
	rightRowItem: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;
/* eslint-disable */
/*
	~ Houses all Mui Custom Theme Derivisions for Material UI Theme NPM Package
*/

import type {} from '@mui/x-date-pickers/themeAugmentation';

import { createTheme } from '@mui/material/styles';


import {
	// HR_HEIGHT,
	// TIMER_BADGE,
	// PLAYER_CANVAS,
	ACTION_COLORS,
	PRIMARY_COLORS,
	// BIN_BAR_HEIGHT,
	// CENTRALIZED_BOX,
	// USER_BAR_HEIGHT,
	SECONDARY_COLORS,
	// GAME_PIECE_BORDER,
	ERROR_COLORS,
	INFO_COLORS,
	__DEFAULT_BLACK,
	__DEFAULT_WHITE,
	// RECORD_MOMENT_HEIGHT,
	// GRADIENT_BACKGROUNDS,
	// DEFAULT_NAVBAR_HEIGHT,
	// PROFILE_MENU_ICON_SQUARE,
	// MAIN_BACKGROUND_CONTAINER,
	// PROFILE_IMAGE_CROPPER_SQUARE,
	// PROFILE_IMAGE_CROPPER_BORDER,
	// USER_BAR_PROFILE_MENU_SQUARESIZE,

} from './index';


export default createTheme({
	palette: {
		background: {
			default: ACTION_COLORS.disabledBackground,
		},
		primary: {
			light: PRIMARY_COLORS.light,
			main: PRIMARY_COLORS.main,
			dark: PRIMARY_COLORS.dark,
			contrastText: PRIMARY_COLORS.contrastText,
		},
		secondary: {
			light: SECONDARY_COLORS.light,
			main: SECONDARY_COLORS.main,
			dark: SECONDARY_COLORS.dark,
			contrastText: SECONDARY_COLORS.contrastText,
		},
		error: {
			light: ERROR_COLORS.light,
			main: ERROR_COLORS.main,
			dark: ERROR_COLORS.dark,
			contrastText: ERROR_COLORS.contrastText,
		},	
		info: {
			light: INFO_COLORS.light,
			main: INFO_COLORS.main,
			dark: INFO_COLORS.dark,
			contrastText: INFO_COLORS.contrastText,
		},
		action: { ...ACTION_COLORS },
		text: {
			primary: __DEFAULT_BLACK,
			secondary: __DEFAULT_WHITE,
		},
	},
	typography: {
		fontSize: 14,
		fontFamily: 'OpenSans',
	},
	breakpoints: {
		values: {
			xl: 1536,
			lg: 1200,
			md: 900,
			sm: 768,
			xs: 200,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					'&.Mui-disabled': {
						backgroundColor: SECONDARY_COLORS.dark,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					color: PRIMARY_COLORS.dark,
					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: SECONDARY_COLORS.main,
						},
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: __DEFAULT_BLACK,
					'&.Mui-focused': {
						color: SECONDARY_COLORS.light,
					},
					'&.Mui-error': {
						color: ERROR_COLORS.main,
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				input: {
					color: SECONDARY_COLORS.main,
				},
				underline: {
					'&:after': {
						borderBottom: `2px solid ${INFO_COLORS.main}`,
					},
					'&.Mui-error:after': {
						borderBottomColor: PRIMARY_COLORS.main,
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: __DEFAULT_BLACK,
					'&.Mui-focused': {
						color: PRIMARY_COLORS.main,
					},
					'&.Mui-error': {
						color: PRIMARY_COLORS.main,
					},
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				"root": {
					"&.Mui-completed": {
						color: INFO_COLORS.main,
					},
				},
			},
		},
		MuiDateTimePickerToolbar: {
			styleOverrides: {
				root: {
					'.MuiDateTimePickerToolbar-timeDigitsContainer': {
						'.MuiDateTimePickerToolbar-separator': {
							color: __DEFAULT_BLACK,
						},
					},
				},
			},
		},
		MuiPickersToolbar: {
			styleOverrides: {
				root: {
					'.MuiButtonBase-root': {
						'.MuiTypography-root': {
							color: __DEFAULT_BLACK,
						},
					},
				},
			},
		},
		MuiDateTimePickerTabs: {
			styleOverrides: {
				root: {
					".MuiButtonBase-root": {
						'&[aria-selected~="false"]': {
							'.MuiSvgIcon-root': {
								fill: PRIMARY_COLORS.main,
							},
						},
						'&[aria-selected~="true"]': {
							backgroundColor: PRIMARY_COLORS.dark,
							'.MuiSvgIcon-root': {
								fill: PRIMARY_COLORS.contrastText,
							},
						},
					},
				},
			},
		},
	},

	// Custom ---------------------------------------------

	// sizes: {
	// 	hrHeight: HR_HEIGHT,
	// 	binBarHeight: BIN_BAR_HEIGHT,
	// 	userBarHeight: USER_BAR_HEIGHT,
	// 	gamePieceBorderHeight: GAME_PIECE_BORDER,
	// 	recordMomentHeight: RECORD_MOMENT_HEIGHT,
	// 	defaultNavbarHeight: DEFAULT_NAVBAR_HEIGHT,
	// },

	// centralizedBox: { ...CENTRALIZED_BOX },
	// gradientBackgrounds: { ...GRADIENT_BACKGROUNDS },
});

/* eslint-enable */
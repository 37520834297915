/**
 * 
 *  ~ MUI UI Library - Input Component
 * 
 */

import includeClassnames from 'classnames';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react/types';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { TextFieldVariants } from '@assets/styles/muiTypes/input';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import {
	TextField, 
	TextFieldProps,

} from '@mui/material';


const Styles = (theme: Theme) => ({
	formControl: {
		width: '100%',
		minHeight: 80,
		marginTop: 10,
		display: 'flex',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export interface iMuiInputProps extends getClassesType<stylesType> {
	label?: string,
    fieldName: string,
	helperText?: string,
	defaultValue?: string,
	inputClassOverride?: string,
	validation?: RegisterOptions,
}


/**
 * 
 * @param props 
 * @returns 
 */
const ControlledInput = (props: iMuiInputProps & Partial<TextFieldProps>) => {
	const {
		fieldName,
		validation,
		helperText,
		inputClassOverride,
		...otherProps

	} = props;

	const formContext = useFormContext();
	let error = '';
	let hasError = false;
	let registration = {};

	if (formContext) {
		const {
			register, 
			formState,
	
		} = formContext;

		const { errors } = formState;
		hasError = Object.hasOwn(errors, fieldName);
		error = hasError ? String(errors[fieldName]?.message) : '';
		registration = {
			...register(fieldName, validation) 
		};
	}

	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<TextField
			{ ...registration }
			{ ...otherProps }
			error={hasError}
			variant={TextFieldVariants.standard}
			helperText={hasError ? error : (helperText || '')}
			classes={{
				root: includeClassnames({
					[classes.formControl]: true,
					[inputClassOverride as string]: inputClassOverride !== undefined,
				})
			}}
		/>
	);
};

export default withStyles(ControlledInput, Styles);
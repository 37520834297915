/*
	~ Navbar With Scroll Effects Component
*/

import Navbar, { iNavbar } from './';
import { ScrollPosition } from '@atoms/_scroll';


interface iNavbarWithScrollEffectsProps extends iNavbar {}

const NavbarWithScrollEffects = (props: iNavbarWithScrollEffectsProps) => {
	return (
		<ScrollPosition>
			<Navbar { ...props } />
		</ScrollPosition>
	);
};


export default NavbarWithScrollEffects;
/**
 * 
 *  ~ Static Data for Main Navigation Component(s)
 * 
 */

import { SyntheticEvent, useMemo } from 'react';
import { ButtonColors } from '@assets/styles/muiTypes/button';
import { NavbarButtonTypes, iNavbarMenuItem } from '@interfaces/navbar';


export interface iMainNavMenuItems {
	onBuyNow?: (event: SyntheticEvent) => void,
	onContact?: (event: SyntheticEvent) => void,
	onAboutUs?: (event: SyntheticEvent) => void,
	onProducts?: (event: SyntheticEvent) => void,
}


export const mainNavMenuItems = ({
	onBuyNow,
	onContact,
	onAboutUs,
	onProducts,

}: iMainNavMenuItems): iNavbarMenuItem[] => useMemo(() => ([
	{
		label: 'Get A Quote',
		type: NavbarButtonTypes.button,
		button: {
			color: ButtonColors.info,
			callback: onBuyNow,
		},
	},
	{
		label: 'About Us',
		type: NavbarButtonTypes.textButton,
		button: {
			callback: onAboutUs,
		},
	},
	{
		label: 'Products',
		type: NavbarButtonTypes.textButton,
		button: {
			callback: onProducts,
		},
	},
	{
		label: 'Contact',
		type: NavbarButtonTypes.textButton,
		button: {
			callback: onContact,
		},
	},

]), []);
/*
	~ Selectable Tile Component
*/

import includeClasses from 'classnames';

import { Theme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { getClassesType } from '@interfaces/tssReact';

import {
	Children, 
	cloneElement, 
	ReactElement, 
	SyntheticEvent,
} from 'react';


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		boxShadow: theme.shadows[3],
		
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			padding: 0,
			flexDirection: 'column',
		},
	} as CSSObject,
	content: {
		padding: 20,
		border: `4px solid rgba(0,0,0,0)`,
		transition: `border-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
	},
	isSelected: {
		borderColor: theme.palette.primary.light,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export interface iSelectableTileProps extends getClassesType<stylesType> {
	key?: string,
	isSelected?: boolean,
	isDisabled?: boolean,
	children?: ReactElement | ReactElement[],
	onChange?: (isSelected: boolean, key?: string, event?: SyntheticEvent) => void,
}

const SelectableTile = (props: iSelectableTileProps) => {
	const {
		key,
		onChange,
		children,
		isSelected,
		isDisabled = false,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	const handleSelectableTileOnClick = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		if (!isDisabled && onChange) {
			onChange(!isSelected, key, event);
		}
	};

	const mapControlledChildren = () => {
		let newTiles: ReactElement[] = [];

		if (children) {
			newTiles = Children.map(
				children,
				(child: ReactElement) => cloneElement(child, {
					isSelected,
				})
			);
		}

		return newTiles;
	};

	return (
		<div 
			onClick={handleSelectableTileOnClick}
			className={
				includeClasses({
					[classes.container]: true,
				})
			}>
			<div 
				className={
					includeClasses({
						[classes.content]: true,
						[classes.isSelected]: isSelected,
					})
				}>

				{ mapControlledChildren() }
			</div>
		</div>
	);
};


export default withStyles(SelectableTile, Styles);
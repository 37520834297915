/**
 * 
 *  ~ Marketing Home Styles
 * 
 */

import { Theme } from '@mui/material';
import { CSSObject } from '@emotion/react';
import { getRGBAColor } from '@assets/styles/helpers';


const Styles = (theme: Theme) => ({
	container: {
		width: '100vw',
		display: 'flex',
		overflowX: 'hidden',
		flexDirection: 'column',
	} as CSSObject,
	landing: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		marginBottom: '12vh',
		flexDirection: 'column',
		justifyContent: 'center',
		
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center 143%',

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginBottom: 0,
		},
	} as CSSObject,
	landingShade: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		position: 'absolute',
		boxShadow: `-1px 10px 25px ${theme.palette.text.primary}`,
		backgroundColor: getRGBAColor(theme.palette.text.primary, 0.05),
	} as CSSObject,
	
	landingFeaturedAd: {
		width: 'auto',
		padding: '4%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: getRGBAColor(theme.palette.primary.dark, 0.7),
		
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			marginTop: 5,
			alignItems: 'center',
			justifyContent: 'center',
		},
		[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
			margin: 5,
		},
	} as CSSObject,
	landingFeatureTitle: {
		marginTop: 15,
		fontWeight: 900,
		marginBottom: 15,
		fontSize: '3.25em',
		textDecoration: 'underline',
		color: theme.palette.primary.contrastText,
		
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingLeft: 10,
			paddingBottom: 10,
			textAlign: 'center',
		},
	} as CSSObject,
	landingFeatureDescription: {
		fontWeight: 400,
		fontSize: '1.25em',
		color: theme.palette.primary.contrastText,

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingLeft: 10,
			paddingBottom: 10,
			textAlign: 'center',
		},
	} as CSSObject,
	seacanman: {
		width: '45vw',
		height: '50vh',
		marginBottom: 25,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',

		[theme.breakpoints.down(theme.breakpoints.values.xl)]: {
			width: '55vw',
			height: '40vh',
		},
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '45vw',
			height: '30vh',
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '80vw',
			height: '34vh',
		},
		[theme.breakpoints.down(theme.breakpoints.values.xs)]: {
			width: '85vw',
			height: '25vh',
		},
	} as CSSObject,

	lureRightDescription: {
		padding: 0,
		fontWeight: 400,
		marginBottom: 20,
		fontSize: '1.25em',
		textAlign: 'right',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			textAlign: 'center',
		},
	} as CSSObject,
	highlighted: {
		width: '100%',
		padding: '15px',
		marginBottom: 25,
	} as CSSObject,
	highlightedText: {
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 400,
		paddingLeft: 20,
		paddingRight: 20,
		fontSize: '2.43em',
		textAlign: 'right',
		lineHeight: '1.25em',
		overflowWrap: 'break-word',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '100%',
			textAlign: 'center',
		},
	} as CSSObject,
	highLightedLeft: {
		textAlign: 'left',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			paddingLeft: 0,
		}
	} as CSSObject,
	highlightedTextLeft: {
		textAlign: 'right',
	} as CSSObject,
	
	infoGatherTitle: {
		fontWeight: 400,
		paddingLeft: 50,
		fontSize: '2.43em',
		lineHeight: '1.25em',
	} as CSSObject,

	schoolsButtonContainer: {
		display: 'flex',
		padding: '2px 20px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	schoolsChevronRight: {} as CSSObject,
	getQuoteButtonLabel: {
		margin: 7.5,
		fontWeight: 600,
		letterSpacing: 2,
		fontSize: '0.9em',
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	ageCategoriesContainer: {
		width: '100%',
		display: 'flex',
		pading: '0 20px',
		marginBottom: 50,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-evenly',

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			flexDirection: 'column',
		},
	} as CSSObject,
	ageCategories: {} as CSSObject,
	lureBoxContent: {
		fontWeight: 500,
		lineHeight: '25px',
	} as CSSObject,
	categoryIcon: {
		fontSize: 16,
		marginLeft: 5,
		marginBottom: 2,
		color: theme.palette.primary.contrastText,
	} as CSSObject,

	expertiseDescription: {
		padding: 0,
		fontWeight: 400,
		marginBottom: 20,
		textAlign: 'left',
		fontSize: '1.25em',
	} as CSSObject,

	orderSection: {
		marginTop: '15vh',
		marginBottom: '20vh',
		
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginBottom: '10vh',
		},
	} as CSSObject,
	orderTitle: {
		width: '100%',
		marginTop: 10,
		marginBottom: 40,
		textAlign: 'center',

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '80vw',
			paddingLeft: '10%',
			paddingRight: '10%',
		},
	} as CSSObject,

	productsSection: {
		display: 'flex',
		marginTop: '15vh',
		alignItems: 'center',
		flexDirection: 'column',
		
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginTop: '2.5vh',
		},
	} as CSSObject,
	productCard: {
		width: '100%',
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			marginTop: 45,
		},
	} as CSSObject,
	productImagesContainer: {
		rowGap: 30,
		width: '90%',
    	marginTop: 40,

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '90%',
		},
	} as CSSObject,
	productPrice: {
		fontWeight: 900,
		fontSize: '1.2rem',
		color: theme.palette.info.main,
	} as CSSObject,

	contactSection: {
		zIndex: 9,
		width: '100%',
		display: 'flex',
		marginTop: '15vh',
		alignItems: 'center',
		paddingBottom: '20vh',
		flexDirection: 'column',
		boxShadow: `0 35px 50px -25px ${theme.palette.text.primary}`,
	} as CSSObject,
	contactBorder: {
		width: '60%',
		display: 'flex',
		paddingTop: '10vh',
		alignItems: 'center',
		paddingBottom: '10vh',
		flexDirection: 'column',
		justifyContent: 'center',
		border: `2px solid ${getRGBAColor(theme.palette.text.primary, 0.8)}`,

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '90%',
		},
	} as CSSObject,

	footer: {
		width: '100%',
		height: '75vh',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		backgroundSize: 'cover',
		justifyContent: 'center',
		backgroundPosition: 'center',
		baxkgroundRepeat: 'no-repeat',
	} as CSSObject,
	seacanmanFooter: {
		width: '65%',
		height: '80%',
		backgroundSize: '75%',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat', 
		backgroundColor: getRGBAColor(theme.palette.text.primary, 0.75),

		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			width: '88%',
			height: '70%',
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '90%',
			height: '45%',
		},
	} as CSSObject,
	footerCopyLawBar: {
		width: '100%',
		display: 'flex',
		padding: '5px 15px',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.secondary.dark,
	} as CSSObject,
	copywrightLabel: {
		color: theme.palette.primary.contrastText,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export default Styles;
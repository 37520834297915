/**
 * 
 * =====================================
 *  ~ APPLICATION ENTRY POINT ----------
 * =====================================
 * 
*/

import App from '@views/App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { StrictMode } from 'react';
import {
	logEvent,
	setUserId,
	getAnalytics,
	setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

// Inject !sole! application CSS setup
/* eslint-disable-next-line */
import './assets/styles/foundationalStyles.css';
// --

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyBWuseSuPbt0chzrIcokmFWF_qBKJ9jHQU',
	authDomain: 'seacanman-c1a09.firebaseapp.com',
	projectId: 'seacanman-c1a09',
	storageBucket: 'seacanman-c1a09.appspot.com',
	messagingSenderId: '99722555065',
	appId: '1:99722555065:web:bb7d151e63b7d208e4b568',
	measurementId: 'G-F4L29ZGWQB'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
	
root.render(
	<StrictMode>
		<App />
	</StrictMode>
);

export const userAnalyticSessionID = `session-${new Date().toISOString()}`;
setUserId(analytics, userAnalyticSessionID);
logEvent(analytics, 'application_initialized_by', {
	id: userAnalyticSessionID,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
	reportWebVitals(console.log);
}

// =====================================
/**
 * 
 *  ~ MUI UI Library - Form Component
 * 
 */


import includeClassnames from 'classnames';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react/types';
import { Theme } from '@mui/system/createTheme';
import { hasChildren } from '@interfaces/React';
import { getClassesType } from '@interfaces/tssReact';
import { ReactElement, type ComponentPropsWithoutRef } from 'react';

import {
	useForm,
	FieldValues,
	FormProvider,
	SubmitHandler,
	DefaultValues,

} from 'react-hook-form';


const Styles = (theme: Theme) => ({
	form: {
		color: theme.palette.primary.main,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iMuiFormProps<FormStructure extends FieldValues> extends 
	getClassesType<stylesType>, 
	hasChildren, 
	Pick<ComponentPropsWithoutRef<'div'>, 'style'> {

	formClassOverride?: string,
	onSubmit: SubmitHandler<FormStructure>,
	defaultValues?: DefaultValues<FormStructure>,
}


const ControlledForm = 
	<FormStructure extends FieldValues>(props: iMuiFormProps<FormStructure>): ReactElement => {
		const {
			style,
			children, 
			onSubmit,
			defaultValues,
			formClassOverride,
		} = props;

		const formMethods = useForm<FormStructure>({
			mode: 'onBlur',
			reValidateMode: 'onSubmit',
			defaultValues: defaultValues ? defaultValues : undefined,
		});

		const { handleSubmit } = formMethods;
    
		return (
			<FormProvider { ...formMethods }>
				<form
					style={style}
					onSubmit={handleSubmit(onSubmit)}
					className={
						includeClassnames({
							[formClassOverride as string]: formClassOverride !== undefined
						})
					}>

					{ children }
				</form>
			</FormProvider>
		);
	};

export default withStyles(ControlledForm, Styles);
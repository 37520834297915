/*
	~ Selectable Tile Group Component
*/

import {
	Children,
	ReactElement,
	cloneElement,
	SyntheticEvent,
} from 'react';

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
// import { useNavigate } from 'react-router-dom';
import { getClassesType } from '@interfaces/tssReact';
import { iSelectableTileProps } from '@atoms/_selectableTile';


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',

		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			gap: 36,
			flexWrap: 'wrap',
		},
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export interface TileState { [key: string]: boolean }

interface iSelectableTileGroupProps<keyTypes> extends getClassesType<stylesType> {
	selectedTile: keyTypes | null,
	onChange?: (selectedTile: keyTypes) => void,
	children?: ReactElement<iSelectableTileProps>[],
}

const SelectableTileGroup = <keyTypes,>(props: iSelectableTileGroupProps<keyTypes>) => {
	const {
		onChange, 
		children,
		selectedTile,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	const handleTileOnChange = (key: keyTypes) => (
		isSelected: boolean, 
		selectedKey?: string, 
		event?: SyntheticEvent
	) => {
		event?.preventDefault();
		event?.stopPropagation();

		if (key && typeof key === 'string') {
			if (onChange) {
				onChange(key);
			}
		}
	};

	const mapControlledChildTiles = () => {
		let newTiles: ReactElement<iSelectableTileProps>[] = [];

		if (children) {
			newTiles = Children.map(children, (child: ReactElement<iSelectableTileProps>) => {
				let isSelected = false;

				if (child?.key) {
					const { key } = child;
					isSelected = selectedTile === key;

					return cloneElement(child, {
						isSelected,
						onChange: handleTileOnChange(key as keyTypes),
					});
				}
			});
		}

		return newTiles;
	};

	return (
		<div className={classes.container}>
			{ mapControlledChildTiles() }
		</div>
	);
};


export default withStyles(SelectableTileGroup, Styles);
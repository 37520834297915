/*
	~ Home Component
*/

import includeClasses from 'classnames';
import ProductCard from '@atoms/_productCard';
import ContactForm from '@molecules/ContactForm';
import ArrowButton from '@molecules/_arrowButton';
import Navbar from '@molecules/Navbar/withScrollEffects';
import BuyContainerStepper from '@atoms/_buyContainerStepper';

import New20Image from '@images/new-20.png';
import New40Image from '@images/new-40.png';
import SeaCanMan from '@images/logo_big.png';
import LandingImage from '@images/halifax.jpg';
import Used40Image from '@images/used_can.jpg';
import CanTunnelImage from '@images/can_tunnel.png';
import DoubleDoorsImage from '@images/side-doors.png';
import LikeNew40Image from '@images/like_new_can.jpg';
import DockCansImage from '@images/dock_top_down.png';
import SideDoorCansImage from '@images/double-doors.png';
import DockyardWorkersImage from '@images/dockyard_workers2.png';
import ShippingYardImage from '@images/ns_shipping_dock_cranes.png';

import Styles, { stylesType } from './styles';
import LureRow, { LureRows } from '@molecules/LureRow';

import { withStyles } from 'tss-react/mui';
import { getClassesType } from '@interfaces/tssReact';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ArrowUpwardSharp } from '@mui/icons-material';
import { mainNavMenuItems } from '@settings/mainNavOptions';
import { ButtonColors } from '@assets/styles/muiTypes/button';

import {
	Grid, 
	Typography,
} from '@mui/material';

import {
	useRef,
	RefObject,
	SyntheticEvent,
	MouseEventHandler,
	useState,
	
} from 'react';


interface iMarketingHome extends getClassesType<stylesType> {}


const Home = (props: iMarketingHome) => {
	const classes = withStyles.getClasses<stylesType>(props);
	// const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

	const orderSectionRef = useRef<HTMLDivElement>(null);
	const aboutUsSectionRef = useRef<HTMLDivElement>(null);
	const productsSectionRef = useRef<HTMLDivElement>(null);
	const contactSectionRef = useRef<HTMLDivElement>(null);

	const [buyContainerKey, setBuyContainerKey] = useState(`stepper_${new Date().toISOString()}`);

	const onLandingButtonClick = () => {
		scrollToSection(orderSectionRef);
	};
	
	const onQuoteButtonClick = () => {
		scrollToSection(orderSectionRef);
	};

	const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
		const offset = ref?.current?.offsetTop;
		
		if (offset !== undefined) {
			window.scrollTo({
				top: offset - 155,
				behavior: 'smooth',
			});
		}
	};

	const renderArrowButton = (
		buttonLabel: string, 
		onClick: MouseEventHandler<HTMLButtonElement>
	) => {
		return (
			<ArrowButton 
				onClick={onClick}
				label={buttonLabel} 
				color={ButtonColors.secondary} />
		);
	};


	const renderQuoteButtonSection = () => {
		return (
			<>
				<h3 className={classes.getQuoteButtonLabel}>
					Order Now
				</h3>

				<ArrowUpwardSharp 
					classes={{
						root: classes.schoolsChevronRight
					}} />
			</>
		);
	};

	
	const renderLanding = () => {
		return (
			<div 
				className={classes.landing}
				style={{
					backgroundImage: `url(${LandingImage})`
				}}>

				<div className={classes.landingShade} />

				<div className={classes.landingFeaturedAd}>
					<div 
						className={classes.seacanman}
						style={{
							backgroundImage: `url(${SeaCanMan})`,
						}} />

					<Typography 
						variant='h4' 
						className={classes.landingFeatureDescription}
						style={{
							marginBottom: '7.5vh',
						}}>
						Serving Atlantic Canada for over 10 years!
					</Typography>

					{ 
						renderArrowButton(
							'Get A Quote Now!',
							onLandingButtonClick
						)
					}
				</div>
			</div>
		);
	};

	const renderWhyChooseUs = () => {
		return (
			<LureRow 
				type={LureRows.left}
				image={DockCansImage}
				ref={aboutUsSectionRef}
				onClick={onQuoteButtonClick}
				buttonLabel={renderQuoteButtonSection()}>
				<>
					<div className={classes.highlighted}>
						<Typography variant='h2' className={classes.highlightedText}>
							Why choose SeaCanMan.ca?
						</Typography>
					</div>

					<h4 className={classes.lureRightDescription}>
						Choosing&nbsp;
						<span style={{
							fontWeight: 900, textDecoration: 'underline' 
						}}>SeaCanMan.ca</span>&nbsp;
						means opting for a company that values quality, affordability, and 
						customer satisfaction. With over ten years of experience serving 
						Atlantic Canada, our commitment to providing the best rates, makes 
						us a leader in the Atlantic shipping container industry.
					</h4>
					
					<h4 className={classes.lureRightDescription}>
						Whether you are looking to buy or customize a shipping container, 
						<strong>SeaCanMan.ca</strong> is your trusted partner. We invite you to 
						explore the possibilities with us and experience the difference that 
						quality and service can make.
					</h4>
				</>
			</LureRow>
		);
	};
	
	const renderExpertise = () => {
		return (
			<LureRow 
				type={LureRows.right}
				image={DockyardWorkersImage}
				onClick={onQuoteButtonClick}
				buttonLabel={renderQuoteButtonSection()}>
				
				<>
					<div className={classes.highlighted}>
						<Typography variant='h2' 
							className={
								includeClasses({
									[classes.highlightedText]: true,
									[classes.highLightedLeft]: true,
								})}>

							Atlantic Canadian Expertise
						</Typography>
					</div>

					<h4 className={classes.expertiseDescription}>
						For over a decade, SeaCanMan.ca has been at the forefront of delivering 
						top-quality shipping container solutions across Atlantic Canada, including 
						Nova Scotia, PEI, Newfoundland, and New Brunswick.
					</h4>
					
					<h4 className={classes.expertiseDescription}>
						Our longstanding presence in the region underlines our commitment to providing 
						the best in both products and services. At SeaCanMan.ca, we pride ourselves on 
						having the most competitive trucking rates and unmatched customer service, 
						ensuring that every interaction with us is seamless and satisfactory.
					</h4>
				</>
			</LureRow>
		);
	};
	
	// const renderServices = () => {
	// 	return (
	// 		<LureRow 
	// 			type={LureRows.left}
	// 			image={ShippingDropoffImage}
	// 			onClick={onQuoteButtonClick}
	// 			buttonLabel={renderQuoteButtonSection()}>
				
	// 			<>
	// 				<div className={classes.highlighted}>
	// 					<Typography variant='h2' className={classes.highlightedText}>
	// 						Custom Tailored Services
	// 					</Typography>
	// 				</div>

	// 				<h4 className={classes.lureRightDescription}>
	// 					At <span style={{
	// 						fontWeight: 900, textDecoration: 'underline' 
	// 					}}>SeaCanMan.ca</span>,
	// 					we believe that a container should be as unique 
	// 					as the needs it serves. That’s why we offer extensive customization services. 
	// 				</h4>
					
	// 				<h4 className={classes.lureRightDescription}>
	// 					Whether you need a mobile office, a pop-up retail space, or a custom storage 
	// 					unit, our team of experts are equipped to transform any container to meet your 
	// 					specifications. Our ability to tailor containers is just another way we stand 
	// 					out in the market, making us a preferred choice for many businesses and individuals.	
	// 				</h4>
	// 			</>
	// 		</LureRow>
	// 	);
	// };


	const renderOrderSteps = () => {
		return (
			<div className={classes.orderSection} ref={orderSectionRef}>
				<Typography variant='h2' className={classes.orderTitle}>
					Get An Instant Quote
				</Typography>
				
				<Typography variant='h6' className={classes.orderTitle} fontStyle={'italic'}>
					Need a container delivered to you? Just follow these steps...
				</Typography>

				<APIProvider apiKey='AIzaSyA6nPLo3lbmh4yS65JNpYtj3nqNzdYhZWU'>
					<BuyContainerStepper 
						key={buyContainerKey} 
						onOrder={() => {
							setBuyContainerKey(`stepper_${new Date().toISOString()}`);
						}} />
				</APIProvider>
			</div>
		);
	};


	const renderProductsSection = () => {
		return (
			<div className={classes.productsSection} ref={productsSectionRef}>
				<Typography variant='h2' className={classes.orderTitle}>
					Our Products
				</Typography>

				<Typography variant='h6' className={classes.orderTitle} fontStyle={'italic'}>
					Our containers are not only versatile but also incredibly durable, with 
					a lifespan of up to 30 years.
				</Typography>

				<LureRow 
					type={LureRows.right}
					image={CanTunnelImage}
					onClick={onQuoteButtonClick}
					buttonLabel={renderQuoteButtonSection()}>
					
					<>
						<div className={classes.highlighted}>
							<Typography variant='h2' className={classes.highlightedText}>
								New & Used Containers
							</Typography>
						</div>

						<h4 className={classes.lureRightDescription}>
							Whether you are in the market for a brand-new container or a cost-effective 
							used option, <span style={{
								fontWeight: 900, 
								textDecoration: 'underline' 
							}}>SeaCanMan.ca</span> has you covered. Our inventory includes both new and 
							used containers, each backed by our robust guarantee, giving you peace of 
							mind with every purchase.
						</h4>
						
						<h4 className={classes.lureRightDescription}>
							We understand that different needs require different solutions, which is why 
							we offer a variety of container types to suit any requirement.
						</h4>
					</>
				</LureRow>

				<Typography
					variant='h2' 
					className={classes.highlightedText}
					style={{
						width: '100%',
						marginTop: 35,
						marginBottom: 25,
						textAlign: 'center',
					}}>
					In-Stock Now
				</Typography>

				<Grid container className={classes.productImagesContainer}>
					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`20' Standard`}
							backgroundUrl={New20Image}
							bannerContent={(
								<>
									Starting at $3,950.<sup>00</sup>
								</>
							)}
							description={(
								<>
									Exterior: 20’L x 8’W x 8’6”H<br />
									Weight: 4,820 LBS<br />
									Payload: 58,625 LBS<br /><br />
									New: <span className={classes.productPrice}>$5,500<sup>.00</sup></span><br />
									Like New: <span className={classes.productPrice}>$4,650<sup>.00</sup></span><br />
									Used: <span className={classes.productPrice}>$3,950<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>

					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`40' High Cube`}
							backgroundUrl={New40Image}
							bannerContent={(
								<>
									Starting at $4,900.<sup>00</sup>
								</>
							)}
							description={(
								<>
									Exterior: 40’L x 8’W x 9’6”H<br />
									Weight: 8,575 LBS<br />
									Payload: 62,380 LBS<br /><br />
									New: <span className={classes.productPrice}>$6,700<sup>.00</sup></span><br />
									Like New: <span className={classes.productPrice}>$5,900<sup>.00</sup></span><br />
									Used: <span className={classes.productPrice}>$4,900<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>
					
					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`Side Doors`}
							backgroundUrl={DoubleDoorsImage}
							bannerContent={(
								<>
									Starting at $12,300.<sup>00</sup>
								</>
							)}
							description={(
								<>
									20' Container: 
									<span className={classes.productPrice}>$12,300<sup>.00</sup></span><br />
									40' Container: <span className={classes.productPrice}>$14,900<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>
					
					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`Double Doors`}
							backgroundUrl={SideDoorCansImage}
							bannerContent={(
								<>
									Starting at $8,100.<sup>00</sup>
								</>
							)}
							description={(
								<>
									40' Container: <span className={classes.productPrice}>$8,100<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>
					
					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`Used Containers`}
							backgroundUrl={Used40Image}
							bannerContent={(
								<>
									Starting at $3,950.<sup>00</sup>
								</>
							)}
							description={(
								<>
									20' Container: 
									<span className={classes.productPrice}>$3,950<sup>.00</sup></span><br />
									40' Container: 
									<span className={classes.productPrice}>$4,900<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>
					
					<Grid item sm={12} md={4} className={classes.productCard}>
						<ProductCard
							title={`"Like New" Containers`}
							backgroundUrl={LikeNew40Image}
							bannerContent={(
								<>
									Starting at $4,700.<sup>00</sup>
								</>
							)}
							description={(
								<>
									20' Container: 
									<span className={classes.productPrice}>$4,650<sup>.00</sup></span><br />
									40' Container: 
									<span className={classes.productPrice}>$5,900<sup>.00</sup></span>
								</>
							)}
						/>
					</Grid>
				</Grid>
			</div>
		);
	};
	
	
	const renderContactSection = () => {
		return (
			<div className={classes.contactSection} ref={contactSectionRef}>
				<div className={classes.contactBorder}>
					<Typography variant='h2' className={classes.orderTitle}>
						Contact Us
					</Typography>

					<Typography variant='h6' className={classes.orderTitle} fontStyle={'italic'}>
						Let's start a conversation about what you are looking for...
					</Typography>

					<ContactForm />
				</div>
			</div>
		);
	};
	
	
	const renderFooter = () => {
		return (
			<>
				<div 
					className={classes.footer}
					style={{
						backgroundImage: `url(${ShippingYardImage})`,
					}}>

					<div
						className={classes.seacanmanFooter}
						style={{
							backgroundImage: `url(${SeaCanMan})`,
						}}
					/>
				</div>

				<div className={classes.footerCopyLawBar}>
					<Typography variant='subtitle2' className={classes.copywrightLabel}>
						Copyright © 2021 - 2024 seacanman.ca CA, All rights reserved.
					</Typography>
				</div>
			</>
		);
	};


	return (
		<div className={classes.container}>
			<Navbar 
				menuItems={
					mainNavMenuItems({
						onBuyNow: (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();

							scrollToSection(orderSectionRef);
						},
						onProducts: (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();

							scrollToSection(productsSectionRef);
						},
						onAboutUs: (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();

							scrollToSection(aboutUsSectionRef);
						},
						onContact: (event: SyntheticEvent) => {
							event.preventDefault();
							event.stopPropagation();

							scrollToSection(contactSectionRef);
						},
					})
				} />
			
			{ renderLanding() }
			{ renderOrderSteps() }
			{ renderWhyChooseUs() }
			{ renderExpertise() }
			{ renderProductsSection() }
			{ renderContactSection() }
			{ renderFooter() }
		</div>
	);
};


export default withStyles(Home, Styles);
/*
	~ Arrow Button Component
*/

import Button from '@atoms/_button';

import { MouseEventHandler } from 'react';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { Theme } from '@mui/system/createTheme';
import { getClassesType } from '@interfaces/tssReact';
import { ArrowDownwardSharp } from '@mui/icons-material';
import { ButtonColors } from '@assets/styles/muiTypes/button';


const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		padding: '2px 20px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	} as CSSObject,
	chevronRight: {} as CSSObject,
	buttonLabel: {
		margin: 7.5,
		fontWeight: 600,
		letterSpacing: 2,
		fontSize: '0.9em',
		color: theme.palette.primary.contrastText,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


interface iArrowButtonProps extends getClassesType<stylesType> {
	label?: string,
	color?: ButtonColors,
	onClick?: MouseEventHandler<HTMLButtonElement>,
}

const ArrowButton = (props: iArrowButtonProps) => {
	const classes = withStyles.getClasses<stylesType>(props);

	const {
		label,
		onClick = () => {/** */},
		color = ButtonColors.primary,
	} = props;

	return (
		<Button 
			color={color}
			onClick={onClick}>

			<div className={classes.container}>
				<h3 className={classes.buttonLabel}>
					{ label }
				</h3>

				<ArrowDownwardSharp
					classes={{
						root: classes.chevronRight
					}} />
			</div>
		</Button>
	);
};


export default withStyles(ArrowButton, Styles);
/*
	~ Product Card Component
*/

import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { ComponentProp } from '@interfaces/React';
import { Theme, Typography } from '@mui/material';
import { getClassesType } from '@interfaces/tssReact';
import { getRGBAColor } from '@assets/styles/helpers';



const Styles = (theme: Theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	productImage: {
		width: '100%',
		height: '40vh',
		overflow: 'hidden',
		position: 'relative',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	} as CSSObject,
	cardBanner: {
		top: 80,
		width: 500,
		padding: 10,
    	right: -143,
		position: 'absolute',
		transform: 'rotate(45deg)',
		backgroundColor: getRGBAColor(theme.palette.info.main, 0.65),
	} as CSSObject,
	cardPrice: {
		fontWeight: 900,
		textAlign: 'center',
		color: theme.palette.info.contrastText,
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;



interface iProductCardProps extends getClassesType<stylesType> {
	title: ComponentProp,
	backgroundUrl: string,
	description: ComponentProp,
	bannerContent: ComponentProp,
}

const ProductCard = (props: iProductCardProps) => {
	const {
		title,
		description,
		bannerContent,
		backgroundUrl,
	} = props;

	const classes = withStyles.getClasses<stylesType>(props);

	return (
		<div className={classes.container}>
			<div 
				className={classes.productImage}
				style={{
					backgroundImage: `url(${backgroundUrl})`,
				}}>

				<div className={classes.cardBanner}>
					<Typography variant='h6' className={classes.cardPrice}>
						{ bannerContent }
					</Typography>
				</div>	
			</div>

			<Typography 
				variant='h5'
				style={{
					marginTop: 25,
					marginBottom: 15,
					textDecoration: 'underline',
				}}>
				{ title }
			</Typography>

			<Typography 
				variant='body1' 
				style={{
					textAlign: 'center',
				}}>
				{ description }
			</Typography>
		</div>
	);
};


export default withStyles(ProductCard, Styles);
/*
	~ Scroll Position Component
*/

import {
	useState,
	Children,
	useEffect,
	ReactElement,
	cloneElement,
	createContext,

} from 'react';


const ScrollPositionContext = createContext({
	windowPosition: 0 
});

export interface iScrollProps {
	children?: ReactElement<any> | ReactElement<any>[],
}


export interface iScrollProviderProps {
	children?: ReactElement<any> | ReactElement<any>[],
}


export const ScrollProvider = (props: iScrollProviderProps) => {
	const { children } = props;
	// const navigate = useNavigate();
	const [windowPosition, setWindowPosition] = useState(0);
	let ticking = false;
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

	}, []);

	const publishScrollPosition = () => {
		ticking = false;
		setWindowPosition(window.scrollY);
	};

	const handleScroll = () => {
		if (!ticking) requestAnimationFrame(publishScrollPosition);

		ticking = true;
	};

	return (
		<ScrollPositionContext.Provider 
			value={{
				windowPosition 
			}}>

			{ children }
		</ScrollPositionContext.Provider>
	);
};


export const ScrollPosition = (props: iScrollProps) => {
	const { children } = props;

	if (children) {
		return (
			<ScrollPositionContext.Consumer>
				{
					({ windowPosition }) => Children.map(children, (child: ReactElement<any>) =>
						cloneElement(child, {
							windowPosition,
						})
					)
				}
			</ScrollPositionContext.Consumer>
		);

	} else {
		return null;
	}
};

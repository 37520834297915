/*
	~ Navbar Component
*/

import NavButton from '@atoms/_button';
import includeClasses from 'classnames';
import SiteLogo from '@images/logo_w.png';
import useMediaQuery from '@mui/material/useMediaQuery';

import Styles, { stylesType } from './styles';

import { Paths } from '@settings/routes';
import { withStyles } from 'tss-react/mui';
import {
	ReactElement, SyntheticEvent, useState 
} from 'react';
import { useTheme } from '@mui/material/styles';
import { Close, Menu } from '@mui/icons-material';
import { getClassesType } from '@interfaces/tssReact';
import { ButtonColors } from '@assets/styles/muiTypes/button';
import { DrawerAnchors } from '@assets/styles/muiTypes/drawer';
import { NavbarButtonTypes, iNavbarMenuItem } from '@interfaces/navbar';

import {
	List,
	Drawer,
	AppBar,
	Toolbar,
	ListItem,
	IconButton,
	ListItemText,
	ListItemButton,

} from '@mui/material';


export interface iNavbar extends getClassesType<stylesType> {
	windowPosition?: number,
    menuItems?: iNavbarMenuItem[],
}


const Navbar = (props: iNavbar) => {
	const {
		menuItems,
		windowPosition,
	} = props;
	
	const theme = useTheme();
	const classes = withStyles.getClasses<stylesType>(props);
	const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
	
	const [isOpen, setIsOpen] = useState(false);
	const activeNavbar = (windowPosition !== undefined && windowPosition > (window.innerHeight + 40));


	const onMobileListItemClick = (callback?: (event: SyntheticEvent) => void | undefined) => 
		(event: SyntheticEvent) => {
			setIsOpen(false);

			if (callback) {
				callback(event);
			}
		};
	
	const handleDrawerOnClose = () => {
		setIsOpen(false);
	};

	const handleMobileDrawerButtonOnClick = () => {
		setIsOpen(true);
	};
	
	const renderMobileDrawer = () => {
		if (isMobile) {
			return (
				<Drawer
					open={isOpen}
					anchor={DrawerAnchors.top}
					onClose={handleDrawerOnClose}>
	
					<div className={classes.mobileDrawerContainer}>
						<div className={classes.drawerHeader}>
							<div className={classes.closeButtonContainer}>
								<IconButton onClick={handleDrawerOnClose}>
									<Close classes={{
										root: classes.closeButtonIcon 
									}} />
								</IconButton>
							</div>
							<img 
								alt={'logo'} 
								src={SiteLogo}
								className={classes.mobileLogo} />

							{ renderTitle() }
						</div>

						{ renderMobileMenuItems() }
					</div>
				</Drawer>
			);

		} else {
			return null;
		}
	};
	
	const renderNavMenuBySize = () => {
		if (isMobile) {
			// Mobile-sized Menu
			return (
				<IconButton 
					onClick={handleMobileDrawerButtonOnClick}
					classes={{
						root: classes.mobileDrawerButton
					}}>

					<Menu />
				</IconButton>
			);

		} else {
			// Medium-sized Menu
			return (
				<div className={classes.menuContainer}>
					{ renderMenuItems() }
				</div>
			);
		}
	};

	const renderMobileMenuItems = () => {
		const items: ReactElement[] = [];

		if (menuItems && menuItems?.length > 0) {
			menuItems?.forEach((item, index) => {
				const {
					label, 
					button,
				} = item;

				if (button) {
					const { callback } = button;
	
					items.push(
						<ListItem key={`mobile_list_item_${index}`}>
							<ListItemButton 
								onClick={onMobileListItemClick(callback)}
								classes={{
									root: classes.mobileListItem
								}}>
	
								<ListItemText primary={label} />
							</ListItemButton>
						</ListItem>
					);
				}
			});
		}

		return (
			<List>
				{ items }
			</List>
		);
	};

	const renderMenuItems = () => {
		const items: ReactElement[] = [];

		if (menuItems && menuItems?.length > 0) {
			menuItems?.forEach((item, index) => {
				const {
					url, 
					type,
					label,
					button,

				} = item;

				switch (type) {
					case NavbarButtonTypes.link:
						items.push(
							<div 
								className={classes.menuItem}
								key={`menu-list-item-${index}`}>
		
								<a 
									href={url} 
									className={classes.menuItemLink}>
		
									{ label }
								</a>
							</div>
						);
						break;

					case NavbarButtonTypes.button:
						if (button) {
							const {
								callback,
								color = ButtonColors.primary,
							} = button;

							if (label) {
								items.push(
									<NavButton
										color={color}
										onClick={callback}
										className={classes.menuItem}
										key={`menu-list-item-${index}`}>
			
										{ label }
									</NavButton>
								);
							}
						}
						break;
						
					case NavbarButtonTypes.textButton:
						if (button) {
							const { callback } = button;

							if (label) {
								items.push(
									<a 
										href={'#'} 
										onClick={callback}
										key={`menu-list-item-${index}`}
										className={classes.menuItemLink}>
			
										{ label }
									</a>
								);
							}
						}
						break;
				
					default:
						break;
				}
			});
		}

		return items;
	};

	const renderTitle = (whiteTitle = false) => {
		return (
			<h1 
				className={
					includeClasses({
						[classes.title]: true,
						[classes.whiteTitle]: whiteTitle,
					})
				}>
				SeaCanMan.ca
			</h1>
		);
	};


	return (
		<AppBar 
			color={'default'}
			position={'static'} 
			classes={{ 
				root: includeClasses({
					[classes.container]: true,
					[classes.activeNavbar]: activeNavbar && !isOpen,
				})
			}}>

			<Toolbar classes={{
				root: classes.container 
			}}>
				<a 
					href={Paths.home}
					className={classes.logoLink}>
					<img 
						alt={'logo'} 
						src={SiteLogo}
						className={classes.logo} />

					{ renderTitle(true) }
				</a>

				{ renderNavMenuBySize() }
				{ renderMobileDrawer() }
			</Toolbar>
		</AppBar>
	);
};


export default withStyles(Navbar, Styles);

/**
 * 
 * 	~ Navbar Styles
 * 
 */

import { Theme } from '@mui/material';
import { CSSObject } from '@emotion/react';


const Styles = (theme: Theme) => ({
	container: {
		height: 70,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.primary.dark,
	} as CSSObject,
	activeNavbar: {
		top: 0,
		left: 0,
		position: 'fixed',
		zIndex: 9999999999999,
	} as CSSObject,
	logoLink: {
		marginLeft: 15,
		display: 'flex',
		marginBottom: 0,
		alignItems: 'center',
		flexDirection: 'row',
		textDecoration: 'none',
		justifyContent: 'center',
	} as CSSObject,
	logo: {
		width: 100,
		height: 100,
	} as CSSObject,
	title: {
		margin: 0,
		fontWeight: 100,
		fontSize: '1.7em',
		letterSpacing: '1px',
		fontFamily: '"OpenSans"',
		color: theme.palette.text.primary,
	} as CSSObject,
	whiteTitle: {
		color: theme.palette.primary.contrastText,
	} as CSSObject,
	beta: {
		top: -22,
		left: -15,
		height: 16,
		fontSize: '0.55rem',
		position: 'relative',
	} as CSSObject,
	menu: {
		listStyle: 'none',
	} as CSSObject,
	menuContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	} as CSSObject,
	menuItem: {
		marginRight: 33,
		marginBottom: 0,
	} as CSSObject,
	menuItemLink: {
		fontWeight: 600,
		marginRight: 30,
		letterSpacing: 1,
		fontSize: '1.2em',
		textDecoration: 'none',
		textTransform: 'uppercase',
		color: theme.palette.primary.contrastText,

		'&:hover': {
			textDecoration: 'underline',
		},
	} as CSSObject,

	mobileDrawerContainer: {
		height: '100vh',
	} as CSSObject,
	mobileDrawerButton: {
		marginRight: 5,
		marginBottom: 15,
	} as CSSObject,
	closeButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	} as CSSObject,
	closeButtonIcon: {
		padding: 35,
		fontSize: 35,
		color: theme.palette.text.primary,
	} as CSSObject,
	drawerHeader: {
		width: '100%',
		display: 'flex',
		marginBottom: 45,
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	} as CSSObject,
	mobileLogo: {
		width: '35%',
		height: 'auto',
	} as CSSObject,
	mobileListItem: {
		textAlign: 'center',
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;

export default Styles;
/*
	~ All Routes
*/


import HomeView from '@views/Home';

import { RouteObject } from 'react-router-dom';

//Route Key Names
export enum RouteNames {
	HOME = 'HOME_KEY',
}

export enum Paths {
	home = '/',
}

export interface iRouteGroup {
	[view_id: string]: RouteObject
}

export interface iRouteGroupWithChildren {
	[view_id: string]: RouteObject & {
		childRoutes?: iRouteGroup,
	},
}


//Route Objects - Ordered By Flow Chronology
export const AllRoutes: iRouteGroupWithChildren = {
	[RouteNames.HOME]: {
		path: Paths.home,
		element: <HomeView />,
	},
};


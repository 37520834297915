/*
	~ Navigator View
*/

import PageNotFoundView from '@views/PageNotFound';

import { ReactNode } from 'react';
import { AllRoutes, iRouteGroup } from '@settings/routes';

import {
	Route,
	Routes,
	BrowserRouter as Router,

} from 'react-router-dom';


const NavigatorView = () => {
	const renderRouteGroup = (group: iRouteGroup) => {
		const routesToRender: ReactNode[] = [];

		if (group) {
			Object.values(group).forEach((route, routeIndex) => {
				routesToRender.push(
					<Route 
						path={route.path}
						element={route.element} 
						key={`route_${routeIndex}`} />
				);
			});
		}

		return routesToRender;
	};

	return (
		<Router>
			<Routes>
				<Route 
					path={'*'} 
					element={<PageNotFoundView />} />

				{ renderRouteGroup(AllRoutes) }
			</Routes>
		</Router>
	);
};

export default NavigatorView;
/**
 * 
 * 	~ Navbar Interfaces & Types
 * 
 */

import { ButtonColorTypes } from '@assets/styles/muiTypes/button';
import { SyntheticEvent } from 'react';


export enum NavbarButtonTypes {
	link,
	button,
	iconButton,
	textButton,
}

export interface iNavbarMenuItem {
    url?: string,
    label?: string,
	type: NavbarButtonTypes,
	button?: {
		color?: ButtonColorTypes,
		callback?: (event: SyntheticEvent) => void,
	},
}
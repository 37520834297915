/**
 * 
 * 	~ Search Places Auto Complete
 * 
 */

import {
	useState,
	useEffect,
	FormEvent,
	useCallback,
} from 'react';

import { Input } from '@mui/material';
import { Theme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CSSObject } from '@emotion/react';
import { getClassesType } from '@interfaces/tssReact';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';


const Styles = (theme: Theme) => ({
	container: {
		position: 'relative',
	} as CSSObject,
	searchList: {
		top: 40,
		left: 0,
		zIndex: 99999,
		padding: '15px 0',
		listStyle: 'none',
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.text.primary}`,
	} as CSSObject,
	searchListItem: {
		cursor: 'pointer',
		padding: '10px 40px',

		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	} as CSSObject,
});

export type stylesType = ReturnType<typeof Styles>;


export interface iAutoCompleteProps extends getClassesType<stylesType> {
	value?: string,
	onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

export const AutoCompletePlaces = (props: iAutoCompleteProps) => {
	const {
		value,
		onPlaceSelect,
	
	} = props;

	const [inputValue, setInputValue] = useState<string>('');
	const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
	const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
	const [predictionResults, setPredictionResults] = useState<Array<google.maps.places.AutocompletePrediction>>([]);
	const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);

	const map = useMap();
	const places = useMapsLibrary('places');
	const classes = withStyles.getClasses<stylesType>(props);


	useEffect(() => {
		if (value !== undefined) {
			setInputValue((oldValue: string | undefined) => {
				if (oldValue !== value) {
					return value;
				
				} else {
					return oldValue;
				}
			});
		}

	}, [value]);


	useEffect(() => {
		if (!places || !map) return;

		setAutocompleteService(new places.AutocompleteService());
		setPlacesService(new places.PlacesService(map));
		setSessionToken(new places.AutocompleteSessionToken());

		return () => setAutocompleteService(null);
	}, [map, places]);

	const fetchPredictions = useCallback(
		async (inputValue: string) => {
			if (!autocompleteService || !inputValue) {
				setPredictionResults([]);
				return;
			}

			const request = {
				input: inputValue, sessionToken 
			};
			const response = await autocompleteService.getPlacePredictions(request);

			setPredictionResults(response.predictions);
		},
		[autocompleteService, sessionToken]
	);

	const onInputChange = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			const value = (event.target as HTMLInputElement)?.value;

			setInputValue(value);
			fetchPredictions(value);
		},
		[fetchPredictions]
	);

	const handleSuggestionClick = useCallback(
		(placeId: string) => {
			if (!places) return;

			const detailRequestOptions = {
				placeId,
				fields: ['geometry', 'name', 'formatted_address'],
				sessionToken
			};

			const detailsRequestCallback = (
				placeDetails: google.maps.places.PlaceResult | null
			) => {
				onPlaceSelect(placeDetails);
				setPredictionResults([]);
				setInputValue(placeDetails?.formatted_address ?? '');
				setSessionToken(new places.AutocompleteSessionToken());
			};

			placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
		},
		[onPlaceSelect, places, placesService, sessionToken]
	);

	return (
		<div className={classes.container}>
			<Input
				value={inputValue}
				placeholder="Search for a place"
				onInput={(event: FormEvent<HTMLInputElement>) => onInputChange(event)}
				style={{
					width: '100%',
					marginTop: 10,
					marginBottom: 25,
				}}
			/>

			{predictionResults.length > 0 && (
				<ul className={classes.searchList}>
					{predictionResults.map(({
						place_id, description 
					}) => {
						return (
							<li
								key={place_id}
								className={classes.searchListItem}
								onClick={() => handleSuggestionClick(place_id)}>

								{ description }
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};


export default withStyles(AutoCompletePlaces, Styles);